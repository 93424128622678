<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <RegisterComponent />
  </div>
</template>

<script>
import RegisterComponent from '../components/RegisterComponent.vue';


export default {
  components: {
    RegisterComponent,
  },
};
</script>
